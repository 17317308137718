import { useEffect, useState } from "react";
import "./courses.scss";
import "../../../src/routes/home/customerSupport//customer-support.scss";
import * as userUtil from "../../utils/user.util";
import { ApiGet } from "../../Helpers/Api/ApiData";
import CardImage from '../../assets/images/Group 410.svg'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useHistory } from "react-router-dom";

// Module Component
export default function Courses() {
  let userInfo = userUtil.getUserInfo();
  const history = useHistory();
  const [courseData, setCourseData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.setItem("CourseName", null);
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    setLoading(true);

    await ApiGet(`course/get-all-course?aid=${userInfo?.aid}&isActive=true`)
      .then((res) => {
        setLoading(false);
        console.log("get courses on course page", res?.data?.payload?.courses);
        setCourseData(res?.data?.payload?.courses);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <div>
      <div className="courses-section">
        <div className="page-title">
          <h1>Alle Module</h1>
          {/* All Courses */}
          <Loader
            className="Loading"
            type="Puff"
            color="#5B5B5E"
            height={35}
            width={35}
            visible={loading}
          />

          {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tellus ut condimentum pretium, at. Justo enim, accumsan faucibus scelerisque. Purus non sociis diam quis a. Mi facilisis adipiscing ultricies odio turpis nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tellus ut condimentum pretium, at. Justo enim, accumsan faucibus scelerisque. 
                    Purus non sociis diam quis a. Mi facilisis adipiscing ultricies odio turpis nibh.
                </p> */}
        </div>
        {courseData?.length === 0 && loading === false ? (
          <>
            <div className="textt">Keine Daten vorhanden.</div>
          </>
        ) : (
          <>
            <div className="grid">
              {courseData?.map((item, index) => {
                return (
                  <>
                    <div className="grid-items">
                      <div className="card-image">
                        <img className="video-player" src={CardImage}></img>
                      </div>
                      <div className="card-detail">
                        <h2 className="lektion">{item?.name}</h2>
                        {/* <p>{item?.description}</p> */}
                        <div className="btn-wrapper">
                          <button
                            onClick={() => {
                              localStorage.setItem("CourseName", item?.name);
                              history.push(`/Courses-details/${item?._id}`);
                            }}
                          >
                                    Modul starten                          
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
