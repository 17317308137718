import "./sidebar.scss";
import React, { useState, useEffect } from "react";
import { ApiGet, ApiPut } from "../../../src/Helpers/Api/ApiData";
import ProfileImage from "../../assets/images/UserDefault.jpeg";
import DownIcon from "../../assets/icons/down-black.svg";
import HomeIcon from "../../assets/icons/home.svg";
import CoursesIcon from "../../assets/icons/courses.svg";
import RecordsIcon from "../../assets/icons/records.svg";
import ProgressIcon from "../../assets/icons/progress.svg";
import menu_icon from "../../assets/icons/menu_icon.svg";
import menu_close from "../../assets/icons/menu_close.svg";
import SearchIcon from "../../assets/icons/search.svg";
import BoardIcon from "../../assets/icons/board.svg";
import Play from "../../assets/icons/play.svg";

import CalendarIcon from "../../assets/icons/Calender.svg";
import QuizIcon from "../../assets/icons/quiz.svg";
import FortbildungIcon from "../../assets/icons/fortbildung.svg";
import Auth from "../../Helpers/auth";
import { useHistory } from "react-router-dom";
import logo from "../sidebar/img/Logoo_new.png";
import logo1 from "../sidebar/img/logo1.png";
import logo4 from "../sidebar/img/FEPromotion-11.svg";
import logo5 from "../sidebar/img/logo5.svg";
import logo2 from "../sidebar/img/logo2.png";
import * as userUtil from "../../utils/user.util";
import { NavLink } from "react-router-dom";

export default function Sidebar() {
  const [UserData, setUserData] = useState({});
  const [img, setImg] = useState();
  const [sidemanu, setSidemanu] = useState(false);

  let userInfo = userUtil.getUserInfo();
  const opansidemanulist = () => {
    setSidemanu(!sidemanu);
  };
  let router = useHistory();
  let route = router.location.pathname;
  const history = useHistory();
  const LogoutFuntion = (e) => {
    e.preventDefault();
    history.push("/logout");
  };

  const getUsers = async () => {
    let userInfo = userUtil.getUserInfo();
    await ApiGet(`admin/get-admin/${userInfo?._id}`)
      .then((res) => {
        console.log("get user", res?.data?.payload?.admin);
        setUserData(res?.data?.payload?.admin);
        setImg(res?.data?.payload?.admin?.photo);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const ProfileData = (e) => {
    e.preventDefault();
    history.push("/Profile");
  };
  console.log("$$$$$$", sidemanu);

  console.log("");

  return (
    <>
      <div className="sidebar-sticky">
        <div className="sidebar">
          <div className="mainLogo">
            <div
              className="firstlogo"
              onClick={() => {
                history.push(`/`);
              }}
            >
              <img src={logo4} alt="" />
            </div>
          </div>
          <div className="sidebar-alignment">
            <div className={route === "/Profile" ? "" : "dactive"}>
              <div className="profile-show" onClick={ProfileData}>
                <div className="profile-details-alignment">
                  <div className="all-profile-info">
                    <div className="profile-image">
                      <img src={!img ? ProfileImage : img} alt="ProfileImage" />
                    </div>
                    <div className="user-details">
                      <p>{UserData?.fname !== undefined ? UserData?.fname + " " + UserData?.lname : ""}</p>
                      {/* <span>Sale manager</span> */}
                    </div>
                  </div>
                  {/* <div>
                <img src={DownIcon} alt="DownIcon" />
              </div> */}
                </div>
              </div>
            </div>

            <div className="sidebar_scroll">
              <div className="sub-menu-alignment">
             
                <div className={route === "/" ? "active" : "dactive"}>
                <NavLink to="/">
                    <div className="sub-menu">
                      <div>
                        <img src={HomeIcon} alt="HomeIcon" />
                      </div>
                      <div>
                        <span className="home">Home</span>
                      </div>
                    </div>
                    </NavLink>
                </div>
                
              
                <div className={route === "/courses" ? "active" : "dactive"}>
                <NavLink to="/courses">
                    <div className="sub-menu">
                      <div>
                        <img src={CoursesIcon} alt="CoursesIcon" />
                      </div>
                      <div>
                        <span className="home1">Module</span>
                        {/* Courses component */}
                      </div>
                    </div>
                    </NavLink>
                </div>
               
                {/* <a to="/records">
                <div className="sub-menu">
                <div>
                  <img src={RecordsIcon} alt="RecordsIcon" />
                </div>
                <div>
                  <span>Records</span>
                </div>
                </div>
              </a> */}

                {/* <div className={route === "/progress" ? "active" : "dactive"}>
                  <NavLink to="/progress">
                    <div className="sub-menu">
                      <div>
                        <img src={ProgressIcon} alt="ProgressIcon" />
                      </div>
                      <div>
                        <span>Fortschritt</span>
                      </div>
                    </div>
                  </NavLink>
                </div> */}
                {/* <div className={route === "/board" ? "active" : "dactive"}>
                  <NavLink to="/board">
                    <div className="sub-menu">
                      <div>
                        <img src={BoardIcon} alt="BoardIcon" />
                      </div>
                      <div>
                        <span>To Do</span>
                      
                      </div>
                    </div>
                  </NavLink>
                </div> */}

                {/* <div className={route === "/calender" ? "active" : "dactive"}>
                  <NavLink to="/calender">
                    <div className="sub-menu">
                      <div>
                        <img src={CalendarIcon} alt="CalendarIcon" />
                      </div>
                      <div>
                        <span>Kalender</span>
                      </div>
                    </div>
                  </NavLink>
                </div> */}

                <div className={route === "/quiz" ? "active" : "dactive"}>
                  <NavLink to="/quiz">
                    <div className="sub-menu">
                      <div>
                        <img src={QuizIcon} alt="QuizIcon" />
                      </div>
                      <div>
                        <span className="home1">Quiz</span>
                      </div>
                    </div>
                  </NavLink>
                </div>

                {/* <div className={route === "/meeting" ? "active" : "dactive"}>
                  <NavLink to="/meeting">
                    <div className="sub-menu">
                      <div>
                        <img src={Play} alt="Play" />
                      </div>
                      <div>
                        <span>Meeting</span>
                      </div>
                    </div>
                  </NavLink>
                </div> */}
                {/* <NavLink to="/video">
                <div className="sub-menu">
                  <div>
                    <img src={FortbildungIcon} alt="FortbildungIcon" />
                  </div>
                  <div>
                    <span>Fortbildung</span>
                  </div>
                </div>
              </NavLink> */}
                {/* <a href="/logout"> */}
                <div className="sub-menu" onClick={(e) => LogoutFuntion(e)}>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                      <path
                        d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"
                        fill="#ffff"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <span className="home1">Abmelden</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-image">
              {/* <h4>Powered by</h4> */}
              {/* <img src={logo5} alt="" className="footer-logo" /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="header">
        <button onClick={opansidemanulist} className="menu_icon">
          <img src={menu_icon} />
        </button>
        {sidemanu ? (
          <>
            <div className="sidebar mobile-sidebar show">
              <div className="sidebar-alignment">
                <button onClick={opansidemanulist} className="menu_icon menu_close">
                  <img src={menu_close} />
                </button>

                <div className="profile-show" onClick={ProfileData}>
                  <div className="profile-details-alignment">
                    <div className="all-profile-info">
                      <div className="profile-image">
                        <img src={!img ? ProfileImage : img} alt="ProfileImage" />
                      </div>
                      <div className="user-details">
                        <p>{UserData?.fname !== undefined ? UserData?.fname + " " + UserData?.lname : ""}</p>
                        {/* <span>Sale manager</span> */}
                      </div>
                    </div>
                    {/* <div>
                    <img src={DownIcon} alt="DownIcon" />
                  </div> */}
                  </div>
                </div>
                <div className="sidebar_scroll">
                  <div className="sub-menu-alignment">
                    <NavLink to="/courses">
                      <div className="sub-menu">
                        <div>
                          <img src={CoursesIcon} alt="CoursesIcon" />
                        </div>
                        <div>
                          <span>Module</span>
                          {/* Courses component */}
                        </div>
                      </div>
                    </NavLink>
                    {/* <a to="/records">
                    <div className="sub-menu">
                    <div>
                      <img src={RecordsIcon} alt="RecordsIcon" />
                    </div>
                    <div>
                      <span>Records</span>
                    </div>
                    </div>
                  </a> */}
                    {/* <NavLink to="/progress">
                      <div className="sub-menu">
                        <div>
                          <img src={ProgressIcon} alt="ProgressIcon" />
                        </div>
                        <div>
                          <span>Fortschritt</span>
                        </div>
                      </div>
                    </NavLink> */}
                    {/* <NavLink to="/calender">
                    <div className="sub-menu">
                      <div>
                        <img src={CalendarIcon} alt="CalendarIcon" />
                      </div>
                      <div>
                        <span>Calendar</span>
                      </div>
                    </div>
                  </NavLink> */}
                    <NavLink to="/quiz">
                      <div className="sub-menu">
                        <div>
                          <img src={QuizIcon} alt="QuizIcon" />
                        </div>
                        <div>
                          <span>Quiz</span>
                        </div>
                      </div>
                    </NavLink>
                    {/* <NavLink to="/video">
                    <div className="sub-menu">
                      <div>
                        <img src={FortbildungIcon} alt="FortbildungIcon" />
                      </div>
                      <div>
                        <span>Fortbildung</span>
                      </div>
                    </div>
                  </NavLink> */}
                    {/* <a href="/logout"> */}
                    <div className="sub-menu" onClick={(e) => LogoutFuntion(e)}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                          <path
                            d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"
                            fill="#ffff"
                          ></path>
                        </svg>
                      </div>
                      <div>
                        <span>Abmelden </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="footer-image">
                <h4>Powered by</h4>
                <img src={logo5} alt="" className="footer-logo" />
              </div> */}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="logo">
          <img src={logo4} />
        </div>

        <img src={SearchIcon} alt="" />
      </div>
    </>
  );
}
